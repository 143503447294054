import logoWhite from '../assets/logo-white.svg';
import location from '../assets/icons/location.svg';
import OpeningStatusHomePage from "../OpeningHours/OpeningStatusHomePage";
import { Fireworks } from '@fireworks-js/react'

export default function Hero() {
    return (
      <div className="bg-[url('assets/shop.webp')] bg-cover relative">
      <div className="h-screen w-screen bg-black opacity-70 absolute"></div>
      <div className="h-screen relative flex flex-col items-center justify-center text-white">
          <img
              className="h-24 w-auto fill-white"
              src={logoWhite}
              alt="Logo"
              data-aos="fade-down"
              data-aos-duration="1500"
          />
          <h1 className="bold text-4xl uppercase font-serif mt-4 text-center" data-aos="fade-down" data-aos-duration="1500">Retouche & Accessoires shop</h1>
         {
          (new Date().getFullYear() === 2024 && new Date().getMonth() === 3) ?
          <div id='firework-container' className='relative'>
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
              <span className='text-5xl font-serif'>5</span>
              <span className='pl-1 align-bottom'>Jaar</span>
            </div>
            <div className='relative opacity-40'>
              <Fireworks options={{opacity: 0.5, hue: { min: 300, max: 340}, brightness: { min: 100, max: 100 }, delay: { min: 70, max: 100} }} />
            </div>
          </div>
          : <></>
         }
          <div className="border-2 border-ra-pink w-1/5 my-8"></div>
          <a href="https://maps.app.goo.gl/nFusBFZcCLwfnxi9A" target='_blank' rel="noreferrer" data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1000">
            <div className='flex flex-row items-center'>
              <img className="h-8 w-auto" src={location} alt="Locatie icoon"/>
              <div className="flex flex-col ml-2">
                <span>Hasseltsebaan 182</span>
                <span>3940 Hechtel-Eksel</span>
              </div>
            </div>
          </a>
          <div className="border-2 border-ra-pink w-1/5 my-8"></div>
          <OpeningStatusHomePage></OpeningStatusHomePage>
        </div>
      </div>
    );
}