import { useEffect, useState } from "react";
import { holidays, openingHours, vacation, OpeningStatus } from "./opening-hours";

export default function OpeningStatusNavbar() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [openingStatus, setOpeningStatus] = useState(OpeningStatus.CLOSED);
    const [openingText, setOpeningText] = useState('Gesloten');

    useEffect(() => {
        // Function to check if the current date is a holiday
        const isHoliday = (date: Date) => {
            return holidays.some(holiday => holiday.date === formatDate(date));
        };

        // Function to check if the current date is in a vacation period
        const isInVacation = (date: Date) => {
            const currentDateStr = formatDate(date);
            return vacation.some(v => compareDate(currentDateStr, '>=', v.from) && compareDate(currentDateStr, '<=', v.until));
        };

        // Function to format date as MM/DD
        const formatDate = (date: Date) => {
            return `${date.getMonth() + 1}/${date.getDate()}`;
        };

        // Function to compare 2 dates of the format MM/DD
        const compareDate = (date1: string, operator: string, date2: string): boolean => {
            switch (operator) {
                case '>=':
                    return new Date(date1) >= new Date(date2);
                case '<=':
                    return new Date(date1) <= new Date(date2);
                default:
                    return false;
            }
        };

        // Get current date and day
        const now = new Date();
        const dayOfWeek = now.getDay();
        const currentOpeningHours = openingHours[dayOfWeek];

        // Determine if shop is open, closed, on vacation, or it's a holiday
        if (currentOpeningHours.from && currentOpeningHours.until) {
            if (isInVacation(now)) {
                setOpeningStatus(OpeningStatus.CLOSED);
                setOpeningText('Jaarlijks verlof');
            } else if (isHoliday(now)) {
                setOpeningStatus(OpeningStatus.HOLIDAY);
                setOpeningText(holidays.find(holiday => holiday.date === formatDate(now))?.name ?? 'Feestdag');
            } else {
                setOpeningStatus(OpeningStatus.OPEN);
                setOpeningText(`Open van ${currentOpeningHours.from} tot ${currentOpeningHours.until}`);
            }
        }
    }, []);

    return (
        <>
            <div className="my-4 text-center py-4 px-6 gap-x-2 min-w-60 rounded-lg border-2 border-ra-pink-750 text-white text-lg relative" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
                <span className="animate-ping absolute top-1 right-1 h-4 w-4 rounded-full bg-ra-pink opacity-75"></span>
                <span className="absolute top-1.5 right-1.5 inline-flex rounded-full h-3 w-3 bg-ra-pink"></span>
                <p className="text-sm">Vandaag</p>
                <p className="mt-2">{openingText}</p>
            </div>
            <p className="mt-4 text-sm underline" data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000"><a href="openingsuren">Bekijk alle openingsuren</a></p>
        </>
    );
}
