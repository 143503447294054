import Stars from "./Stars";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Review(props: any) {
    return (
    <div className={classNames(
        props.review.length > 100 ? 'w-72' : 'w-52',
        "rounded-xl bg-white border-2 border-ra-pink-250 p-4 text-left shadow-lg"
    )}>
        <Stars amount={props.stars}></Stars>
        <p className="mt-4">{props.review}</p>
        <p className="mt-4 font-semibold text-xs">{props.name}</p>
    </div>
    );
}