import Navbar from "./Shared/Navbar";
import { Footer } from "./Shared/Footer";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import OpeningHours from "./OpeningHours/OpeningHours";
import React from "react";
import 'aos/dist/aos.css';
import { Home } from "./Home/Home";

  const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/openingsuren",  
    element: <OpeningHours />,
  },
]);
export class App extends React.Component  {
  componentDidMount() {
    window.location.hash = window.decodeURIComponent(window.location.hash);
    const scrollToAnchor = () => {
      const hashParts = window.location.hash.split('#');
      const hash = hashParts[1]
      if (hash != null) {
        document.querySelector(`#${hash}`)?.scrollIntoView();
      }
    };
    scrollToAnchor();
    window.onhashchange = scrollToAnchor;
  }

  render () {
    return <>
      <Navbar></Navbar>
      <RouterProvider router={router} />
      <Footer></Footer>
    </>
  }
}

export default App;
