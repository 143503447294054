import retouche1 from '../assets/retouche1.webp';
import retouche2 from '../assets/retouche2.webp';
import retouche3 from '../assets/retouche3.webp';

export default function Retouches() {
    return (
      <div id="retouches" className='!scroll-mt-16'>
        <div className="bg-ra-green-250 p-10 lg:px-40 grid gap-4 md:grid-cols-2 overflow-hidden">
        <div data-aos="fade-right" data-aos-duration="500">
          <h2 className="text-2xl font-semibold uppercase mb-4 font-serif">Retouches</h2>
          <p>Bij ons ben je aan het juiste adres voor alle herstellingen en retouches van kledingstukken!</p>
          <p className="mt-4">Enkele van de diensten die wij bieden zijn onder andere:</p>
          <ul className="list-disc ml-6">
            <li>Broeken inkorten of uitlaten</li>
            <li>Ritsen vervangen</li>
            <li>Kledingstukken versmallen of verbreden</li>
            <li>Gordijnen inkorten of herstellen</li>
          </ul>
          <p className="mt-4">Onze expertise gaat verder dan deze voorbeelden. We zijn flexibel en bereid om te luisteren naar jouw specifieke behoeften en wensen. </p>
          <p className="mt-4">Benieuwd naar wat we voor jou kunnen doen? Aarzel niet om ons om eerlijk advies te vragen.</p>
        </div>
        <div className="relative min-h-96">
          <img data-aos="fade-left" data-aos-duration="500" className="absolute object-cover h-3/6 w-6/12 rounded-2xl hover:z-10 duration-300 transition ease-in-out hover:scale-110" src={retouche1} alt="Naaimachine" />
          <img data-aos="fade-left" data-aos-duration="500" className="absolute top-1/2 left-1/2 object-cover h-3/6 w-6/12 rounded-2xl hover:z-10 duration-300 transition ease-in-out hover:scale-110" src={retouche3} alt="Handmatig naaien" />
          <img data-aos="fade-left" data-aos-duration="500" className="absolute top-1/4 left-1/4 object-cover h-3/6 w-6/12 rounded-2xl hover:z-10 duration-300 transition ease-in-out hover:scale-110" src={retouche2} alt="Broek inkorten" />
        </div>
      </div>
      </div>
    );
}