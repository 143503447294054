import { openingHours } from "./opening-hours";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function OpeningHours() {
    let openingHoursArray = [...openingHours];

    if (openingHoursArray[0].name === 'Zondag') {
        const sunday = openingHoursArray.shift() ?? { name: 'Zondag', from: null, until: null }
        openingHoursArray.push(sunday)
    }

    return (
        <>
            <div className="bg-[url('assets/background-clock.webp')] bg-cover relative">
            <div className="h-full w-screen bg-black opacity-70 absolute"></div>
            <div className="pt-16 p-10 lg:px-40 relative">
                <div className="my-8">
                    <h2 className="font-bold text-4xl uppercase mb-8 text-white text-center font-serif" data-aos="fade-down">Openingsuren</h2>
                    <div className="text-center my-8">
                        <p className="text-white text-xl font-serif">*Wij zijn gesloten op feestdagen.</p>
                    </div>
                    <div className="grid gap-4 grid-cols-12">
                        {
                                openingHoursArray.map((day, index) => (
                                    <div key={index} className={classNames(
                                        index === 6 ? 'md:col-start-4 lg:col-start-5' : '',
                                        'pt-6 pb-2 px-6 text-center flex-grow rounded-xl bg-white-750 col-span-12 md:col-span-6 lg:col-span-4'
                                    )}
                                    data-aos="flip-left" data-aos-delay={(index*100)+300} data-aos-offset="-10"
                                    >
                                        {
                                            day.from && day.until 
                                            ?   <p className={classNames(
                                                index === (new Date().getDay() === 0 ? 6 : new Date().getDay()-1) ? 'border-b-4 border-ra-pink !inline-block' : '',
                                                "flex flex-row items-center justify-center"
                                            )}>
                                                    <span className="text-2xl">{day.from.split(':')[0]}</span>
                                                    <span>:</span>
                                                    <span className="text-2xl">{day.from.split(':')[1]}</span>
                                                    <span className="px-2">-</span>
                                                    <span className="text-2xl">{day.until.split(':')[0]}</span>
                                                    <span>:</span>
                                                    <span className="text-2xl">{day.until.split(':')[1]}</span>
                                                </p>
                                            :   <p className="text-2xl">Gesloten</p>
                                        }
                                        <p className="font-serif mt-4 italic">{day.name}</p>
                                    </div>
                                ))
                            }
                    </div>
                </div>
                <div className="my-8 mt-20">
                    <h2 className="font-bold text-4xl uppercase mb-8 text-white text-center font-serif" data-aos="fade-down">Verlofperiodes</h2>
                    <ol className="items-center sm:flex sm:items-baseline mx-10">
                        <li className="relative mb-6 sm:mb-0 sm:flex-1 flex flex-row gap-4 sm:block" data-aos="fade-right" data-aos-delay="200">
                            <div className="flex items-center">
                                <div className="z-10 flex items-center justify-center w-8 h-8 bg-ra-pink rounded-full ring-0 ring-white sm:ring-4 shrink-0">
                                    <svg className="w-3.5 h-3.5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                                    </svg>
                                </div>
                                <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
                            </div>
                            <div className="mt-3 sm:pe-8">
                                <p className="text-xl font-semibold text-white">Zo. 12 mei -</p>
                                <p className="text-xl font-semibold text-white">Vr. 24 mei</p>
                            </div>
                        </li>
                        <li className="relative mb-6 sm:mb-0 sm:flex-1 flex flex-row gap-4 sm:block" data-aos="fade-right" data-aos-delay="100">
                            <div className="flex items-center">
                                <div className="z-10 flex items-center justify-center w-8 h-8 bg-ra-pink rounded-full ring-0 ring-white sm:ring-4 shrink-0">
                                    <svg className="w-3.5 h-3.5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                                    </svg>
                                </div>
                                <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
                            </div>
                            <div className="mt-3 sm:pe-8">
                                <p className="text-xl font-semibold text-white">Ma. 30 september -</p>
                                <p className="text-xl font-semibold text-white">Di. 8 oktober</p>
                            </div>
                        </li>
                        <li className="relative mb-6 sm:mb-0 sm:flex-1 flex flex-row gap-4 sm:block" data-aos="fade-right">
                            <div className="flex items-center">
                                <div className="z-10 flex items-center justify-center w-8 h-8 bg-ra-pink rounded-full ring-0 ring-white sm:ring-4 shrink-0">
                                    <svg className="w-3.5 h-3.5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                                    </svg>
                                </div>
                                <div className="hidden sm:flex w-full bg-gray-200 h-0.5"></div>
                            </div>
                            <div className="mt-3 sm:pe-8">
                                <p className="text-xl font-semibold text-white">Ma. 23 december -</p>
                                <p className="text-xl font-semibold text-white">Vr. 3 januari</p>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
            </div>
        </>
    );
}
