import Review from "./Review";

export default function Reviews() {
    return (
      <div className="h-96 bg-[url('assets/background.webp')] bg-fixed relative">
        <div className="h-full w-screen bg-white opacity-70 absolute"></div>
        <div className="relative h-full flex flex-col justify-around p-10 lg:px-40">
          <h2 className="text-4xl text-center font-bold font-serif">Wat onze klanten zeggen</h2>
          <div className="xl:w-4/5 inline-flex flex-nowrap self-center overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-100px),transparent_100%)] leading-4" style={{maxWidth: '100vw'}}>
            <ul className="flex items-center justify-center md:justify-start [&_li]:mx-1 [&_img]:max-w-none animate-infinite-scroll">
                <li>
                    <Review name="Martine" stars="5" review="Kent haar vak als geen ander! Een absolute aanrader!"></Review>
                </li>
                <li>
                    <Review name="Candice" stars="5" review="Super vriendelijke dame, helpt je onmiddellijk. Geeft enorm veel uitleg. Ze staat je met raad en d(r)aad bij."></Review>
                </li>
                <li>
                    <Review name="Fien" stars="5" review="Super!"></Review>
                </li>
                <li>
                    <Review name="Dirk" stars="5" review="Gordijnen laten op maat maken, zelfs rot gordijnen goed gemaakt en ze hangen nu perfect."></Review>
                </li>
                <li>
                    <Review name="Nicky" stars="5" review="Mijn winterjas laten herstellen door Yolanda en ze heeft dit heel goed gedaan! Houdt je op de hoogte van eventuele extra kosten en is super vriendelijk!"></Review>
                </li>
                <li>
                    <Review name="Jonas" stars="5" review="Top madam zeer snelle service en super gedaan. Zeker aan te raden!"></Review>
                </li>
                <li>
                    <Review name="Jonathan" stars="5" review="Goede service, correcte prijs."></Review>
                </li>
            </ul>
            <ul className="flex items-center justify-center md:justify-start [&_li]:mx-1 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
                <li>
                    <Review name="Martine" stars="5" review="Kent haar vak als geen ander! Een absolute aanrader!"></Review>
                </li>
                <li>
                    <Review name="Candice" stars="5" review="Super vriendelijke dame, helpt je onmiddellijk. Geeft enorm veel uitleg. Ze staat je met raad en d(r)aad bij."></Review>
                </li>
                <li>
                    <Review name="Fien" stars="5" review="Super!"></Review>
                </li>
                <li>
                    <Review name="Dirk" stars="5" review="Gordijnen laten op maat maken, zelfs rot gordijnen goed gemaakt en ze hangen nu perfect."></Review>
                </li>
                <li>
                    <Review name="Nicky" stars="5" review="Mijn winterjas laten herstellen door Yolanda en ze heeft dit heel goed gedaan! Houdt je op de hoogte van eventuele extra kosten en is super vriendelijk!"></Review>
                </li>
                <li>
                    <Review name="Jonas" stars="5" review="Top madam zeer snelle service en super gedaan. Zeker aan te raden!"></Review>
                </li>
                <li>
                    <Review name="Jonathan" stars="5" review="Goede service, correcte prijs."></Review>
                </li>
            </ul>
          </div>
        </div>
      </div>
    );
}