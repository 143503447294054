import React from "react";
import Accessories from "./Accessories";
import Hero from "./Hero";
import RetoucheBox from "./RetoucheBox";
import Retouches from "./Retouches";
import Reviews from "./Reviews/Reviews";
import Duurzaamheid from "./Duurzaamheid";

export class Home extends React.Component {
    render () {
        return <>
            <Hero></Hero>
            <RetoucheBox></RetoucheBox>
            <Retouches></Retouches>
            <Duurzaamheid></Duurzaamheid>
            <Accessories></Accessories>
            <Reviews></Reviews>
        </>
    }
}