import duurzaamheid from '../assets/duurzaamheid.webp';

export default function Duurzaamheid() {
    return (
      <div className="bg-[url('assets/fabric.webp')] bg-fixed bg-cover relative">
        <div className="h-full w-screen bg-white opacity-85 absolute"></div>
        <div className="relative h-full text-center flex flex-col justify-around p-10 lg:px-40">
          <div>
            <h2 className="text-4xl font-bold font-serif" data-aos="fade-down">Duurzaamheid</h2>
            <p className='text-lg md:text-xl pt-4' data-aos="fade-down">Van alle oude kleding kan wel iets moois gemaakt worden. Van een oude broek met scheuren kan een short gemaakt worden. Dus gooi niet zomaar de oude kleren weg. Als er iets kapot is van kleding, kom gerust langs en ik zal met plezier bekijken wat mogelijk is.</p>
          </div>
          <div className='mt-6'>
            <img data-aos="fade-down" data-aos-duration="500" className='md:w-2/3 mx-auto' src={duurzaamheid} alt="" />
          </div>
        </div>
      </div>
    );
}