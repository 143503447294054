import { useEffect, useState } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
    { name: 'Home', href: '/', current: true },
    { name: 'Retouches', href: '/#retouches', current: false },
    { name: 'Accessoires', href: '/#accessoires', current: false },
    { name: 'Openingsuren', href: '/openingsuren', current: false },
    { name: 'Contact', href: '/#contact', current: false },
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
    const [isScrolled, setIsScrolled] = useState(window.scrollY > 0);
    useEffect(() => {
        setIsScrolled(window.scrollY > 0);
        window.addEventListener("scroll", () => {
            setIsScrolled(window.scrollY > 0);
        });
    }, []);

    return (
        <Disclosure as="nav" className="sticky top-0 w-full z-50">
            {({ open }) => (
                <>
                    <div className={classNames(
                        isScrolled ? 'bg-white' : '',
                        'mx-auto px-2 lg:px-6 absolute w-full duration-300'
                    )}>
                        <div className="relative flex h-16 items-center justify-betwee z-50">
                            <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className={
                                    classNames(
                                        open ? 'text-gray-500' : isScrolled ? 'text-gray' : 'text-white',
                                        'relative inline-flex items-center justify-center rounded-md p-2 hover:bg-ra-pink hover:text-white focus:outline-none ring-2 ring-inset ring-white'
                                    )
                                }>
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center lg:justify-start">
                                <div className="hidden lg:ml-12 lg:block w-full">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.current ? 'border-b-2 border-ra-pink' : ' hover:border-ra-pink hover:border-b-2',
                                                    isScrolled ? 'text-gray-500' : 'text-white',
                                                    'px-4 h-16 py-5 text-sm font-medium'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Transition
                        show={open}
                        enter="transition duration-300 ease-out transform"
                        enterFrom="-translate-y-full"
                        enterTo="translate-y-0"
                        leave="transition duration-300 ease-out transform"
                        leaveFrom="translate-y-0"
                        leaveTo="-translate-y-full"
                    >
                        <Disclosure.Panel className="lg:hidden bg-white h-screen">
                            <div className="flex flex-col items-center py-16 h-screen justify-center">
                                {navigation.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        href={item.href}
                                        className={classNames(
                                            item.current ? 'text-ra-pink' : 'text-gray-500 hover:border-ra-pink',
                                            'py-4 font-medium text-2xl border-b-2 border-transparent'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    )
}
