import kledingBox from '../assets/kleding-box.webp';

export default function RetoucheBox() {
    return (
      <div className="py-10 lg:py-40 bg-[url('assets/background.webp')] bg-fixed relative overflow-hidden">
        <div className="h-full w-screen bg-white opacity-70 absolute top-0"></div>
        <div className="p-10 lg:px-40 bg-ra-pink text-white relative">
          <div className="lg:w-2/3" data-aos="fade-right" data-aos-duration="500">
            <h2 id="kledingbox" className="text-2xl font-semibold uppercase mb-4 font-serif">Retouche Kleding-Box</h2>
            <p>R & A Retouche shop heeft ook een retouche kleding box hierin kan u op elk moment uw retouches deponeren met uw gegevens + het werk wat gedaan moet worden. Dan zorgen wij voor een prachtig resultaat, als uw retouche klaar is zullen wij met u contact opnemen.</p>
          </div>
        </div>
        <img className="h-96 relative rounded-2xl shadow-2xl mx-auto mt-10 lg:h-5/6 lg:absolute lg:left-2/3 lg:top-10 lg:mx-0 lg:mt-0" src={kledingBox} alt="Kleding-box" data-aos="fade-left" data-aos-duration="500" />
      </div>
    );
}