import accessoire1 from '../assets/accessoire1.webp';
import accessoire2 from '../assets/accessoire2.webp';
import accessoire3 from '../assets/accessoire3.webp';
import accessoire3easter from '../assets/accessoire3-pasen.webp';

export function isEaster(): boolean {
  if (new Date().getMonth() === 3 && new Date().getDate() >= 1 && new Date().getDate() <= 14) {
    return true
  }

  return false
}

export default function Accessories() {
  const accessoires: {src: string, alt: string}[] = [
    {src: accessoire1, alt: 'Haarspeld'},
    {src: accessoire2, alt: 'Kaarslichtje'},
    {src: accessoire3, alt: 'Collage'}
  ]

  if (isEaster()) {
    accessoires[2] = {src: accessoire3easter, alt: 'Collage Pasen'}
  }

  return (
    <div id="accessoires" className='!scroll-mt-16'>
      <div className="bg-ra-pink-250 p-10 lg:px-40 grid gap-4 md:grid-cols-2 overflow-hidden">
        <div data-aos="fade-right" data-aos-duration="500" data-aos-offset="350">
          <h2 className="text-2xl font-semibold uppercase mb-4 font-serif">Accessoires</h2>
          <p>Creativiteit ontbreekt ook niet bij ons. We zijn gepassioneerd over accessoires die je stijl compleet maken en je persoonlijkheid laten stralen!</p>
          <p className="mt-4">Onze collectie bevat een breed scala, van handtassen tot oorbellen en zelfs kussenovertrekken. Maar wat onze accessoires echt speciaal maakt is de creativiteit waarmee ze zijn ontworpen: met behulp van allerlei soorten (oude) kledingmaterialen. Dit wijst op ons duurzaamheid karakter!</p>
          <p className="mt-4">Het maakt niet uit of je op zoek bent naar een speciaal cadeau of gewoon je eigen stijl wilt versterken - onze unieke accessoires zijn een geweldige keuze.</p>
          <p className="mt-4">De accessoires zijn uniek en beperkt! Voor up-to-date te blijven houdt u best <a className="underline" href="https://www.facebook.com/profile.php?id=100063517031826" target="_blank" rel="noreferrer">onze Facebook</a> pagina in het oog.</p>
        </div>
        <div className="relative min-h-96">
          <img data-aos="fade-left" data-aos-duration="500" data-aos-offset="350" className="absolute left-1/2 object-cover h-3/6 w-6/12 rounded-2xl hover:z-10 duration-300 transition ease-in-out hover:scale-110" src={accessoires[0].src} alt={accessoires[0].alt}  />
          <img data-aos="fade-left" data-aos-duration="500" data-aos-offset="350" className="absolute top-1/2 object-cover h-3/6 w-6/12 rounded-2xl hover:z-10 duration-300 transition ease-in-out hover:scale-110" src={accessoires[1].src} alt={accessoires[1].alt}  />
          <img data-aos="fade-left" data-aos-duration="500" data-aos-offset="350" className="absolute top-1/4 left-1/4 object-cover h-3/6 w-6/12 rounded-2xl hover:z-10 duration-300 transition ease-in-out hover:scale-110" src={accessoires[2].src} alt={accessoires[2].alt}  />
        </div>
      </div>
    </div>
  );
}