import logo from '../assets/logo-black.svg';
import facebookLogo from '../assets/icons/facebook.svg';
import instagramLogo from '../assets/icons/instagram.svg';
import location from '../assets/icons/location.svg';
import phone from '../assets/icons/phone.svg';
import mail from '../assets/icons/mail.svg';
import percent from '../assets/icons/percent.svg';

export function Footer() {
  return (
    <>
    <div id='contact' className="w-full my-8 md:flex md:flex-row md:justify-around md:items-center">
        <div className='flex-1 text-sm flex flex-col items-center'>
            <ul>
                <li>
                    <a href="https://maps.app.goo.gl/nFusBFZcCLwfnxi9A" target='_blank' rel="noreferrer">
                        <div className='flex flex-row items-center'>
                            <img className="h-4 w-auto" src={location} alt="Locatie icoon"/>
                            <span className='ml-1'>Hasseltsebaan 182, 3940 Hechtel-Eksel</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a className='mt-1' href="tel:+32474364290" target='_blank' rel="noreferrer">
                        <div className='flex flex-row items-center'>
                            <img className="h-4 w-auto" src={phone} alt="Telefoon icoon"/>
                            <span className='ml-1'>+32 474 36 42 90</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a className='mt-1' href="mailto:yolanda_retouches@hotmail.com" target='_blank' rel="noreferrer">
                        <div className='flex flex-row items-center'>
                            <img className="h-4 w-auto" src={mail} alt="Email icoon"/>
                            <span className='ml-1 truncate'>yolanda_retouches@hotmail.com</span>
                        </div>
                    </a>
                </li>
                <li>
                    <div className='flex flex-row items-center'>
                        <img className="h-4 w-auto" src={percent} alt="Procent icoon"/>
                        <span className='ml-1'>BE 0870.464.538</span>
                    </div>
                </li>
            </ul>
        </div>
        <div className='flex-1 flex flex-row items-center'>
            <span className="flex-1 rounded-full w-1 h-px bg-black inline-block"></span>
            <img
              className="h-20 w-auto mx-auto"
              src={logo}
              alt="Logo"
            />
            <span className="flex-1 rounded-full w-1 h-px bg-black inline-block"></span>
        </div>
        <div className='flex-1'>
            <div className='w-full flex flex-row justify-center'>
                <a href="https://www.facebook.com/profile.php?id=100063517031826" target='_blank' rel="noreferrer">
                    <img className="h-6 w-auto mr-2" src={facebookLogo} alt="Facebook"/>
                </a>
                <a href="https://www.instagram.com/hamalyolanda" target='_blank' rel="noreferrer">
                    <img className="h-6 w-auto ml-2" src={instagramLogo} alt="Facebook" />
                </a>
            </div>
            <p className='text-sm underline text-center mt-4'><a href="openingsuren">Openingsuren</a></p>
        </div>
    </div>
    </>
  )
}